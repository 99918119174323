import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

Vue.mixin({
  data() {
    return {

    OverWrites:null
    }
  },
  mounted(){
    if(this.settings.DefaultOverwrite)
      this.OverWrites = JSON.parse(this.settings.DefaultOverwrite)
  },
  computed: {
    ...mapGetters([
      'user_by_id',
    ]),
    ...mapState([
      'settings',
    ]),
  },
  methods:{
    getOverWrite(property){
      if(this.OverWrites && this.OverWrites[property]){
        return this.OverWrites[property];
      } else {
        return null
      }
    }
  }
})