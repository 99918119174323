<template>
  <b-container v-if="createAny('users')" fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.planning") }} per week </h1>
      <div @click="downloadThisweek()" class="btn btn-primary">
              <font-awesome-icon icon="download" />Download
          </div>
    </div>

    <!-- Filter bar -->
    <div class="filter-bar">
      <b-row>
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.from')">
            <b-form-input type="week" v-model="startw" />
          </b-form-group>
        </b-col>
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.to')">
            <b-form-input type="week" v-model="endw" />
          </b-form-group>
        </b-col>
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.search')">
            <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('strings.typetosearch')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" xl>
          <b-form-group :label="$t('labels.branche')">
            <b-form-select name="branches" class="form-control" v-model="currentbranche">
              <option value="-1"> {{ $t("option.all") }}</option>
              <option v-for="(branche,idx) in branches" :key="'branche'+idx" v-bind:value="branche.id">
                {{ branche["name"]}}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" md="3">
          <b-form-group :label="$t('labels.showby')">
            <b-form-radio-group id="radio-group-1" v-model="bycompany" name="radio-options">
              <b-form-radio name="showby" value="no">{{ $t("labels.byzzpr") }}</b-form-radio>
              <b-form-radio name="showby" value="yes">{{ $t("labels.bycompany") }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="3">
          <b-form-group :label="$t('labels.hoursfilled')">
            <b-form-radio-group id="radio-group-1" v-model="hashours" name="radio-options2">
              <b-form-radio name="hoursfilled" value="no">{{ $t("labels.no") }}</b-form-radio>
              <b-form-radio name="hoursfilled" value="yes">{{ $t("labels.yes") }}</b-form-radio>
              <b-form-radio name="hoursfilled" value="doesnt">{{ $t("labels.doesnotmatter") }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="3">
          <b-form-group :label="$t('titles.itemsvisible')">
            <select name="admin" class="form-control admins" v-model="perPage">
              <option value=1000> {{ $t("option.all") }}</option>
              <option value=10>10</option>
              <option value=20>20</option>
              <option value=50>50</option>
              <option value=100>100</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="3">
          <b-form-group :label="$t('labels.addedby')">
              <select name="admin" class="form-control admins" v-model="owner">
                <option value="-1"> {{ $t("option.all") }}</option>

                <option v-for="(user, idx) in owners" :key="idx" v-bind:value="user.id">
                  {{ user["firstname"] }} {{ user["middlename"] }} {{ user["lastname"] }}
                </option>
              </select>
            </b-form-group>
        </b-col>
      </b-row>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th v-if="bycompany=='yes'">{{ $t("labels.client") }}</th>
          <th v-else>{{ $t("labels.ZZPer") }}</th>
          <th v-for="{index, name,period,numzzprers} in numberofweeks" :key="'zzpweeknum'+index"><strong>Week :</strong>
            {{name}} <strong>{{ $t("labels.period") }} :</strong> {{period}} <strong>{{ $t("labels.ZZPer") }}</strong>
            {{numzzprers}} </th>
        </tr>
      </thead>
      <tbody v-if="bycompany=='yes'">
        <tr v-for="{contract_id,company_name,company_id} in pagedcompanies" :key="'companiesvis'+contract_id">
          <td>
            <a @click="show_company(company_id)" class="link-item">{{ company_name }} </a><br />
          </td>
          <td v-for="{index, name} in numberofweeks" :key="'userweeknum'+index" v-bind:style="blockcolor(contract_id)">
            <UserWeekPlanning :contract_id=contract_id :week=name></UserWeekPlanning>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="{company_id,contract_id, user_id} in pagedcompanies"
          :key="'companies'+company_id+user_id+contract_id">
          <td>
            <UserPlanningBlock :user_id=user_id></UserPlanningBlock>
          </td>
          <td v-for="{index, name} in numberofweeks" :key="'compweeknum'+index" v-bind:style="blockcolor(contract_id)">
            <CompanyPlanningsBlock :contract_id=contract_id :week=name></CompanyPlanningsBlock>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>

  </b-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import UserWeekPlanning from './parts/UserWeekPlanning'
import UserPlanningBlock from './parts/UserPlanningBlock'
import CompanyPlanningsBlock from './parts/CompanyPlanningsBlock'

export default {
  name: 'planningscreenweek',
  data() {
    return {
      currentbranche: -1,
      startw: "",
      endw: "",
      bycompany: 'yes',
      hashours: 'doesnt',
      filter: null,
      visible_companies_gen:[],
      currentPage: 1,
      perPage: 1000,       
      totalRows:0,
      owner:-1
    }
  },
  mounted() {
    this.startweekinit();

  },
  computed: {
    pagedcompanies() {
      return this.visible_companies.slice((this.currentPage-1)*this.perPage,(this.currentPage)*this.perPage)
    },
    numberofweeks() {
      let startyear = parseInt(this.startw.substring(0, 4))
      let startweek = parseInt(this.startw.substring(6))
      // console.log(startweek, startyear)
      // var d = new Date(startyear, 0, 1);
      // if(d.getDay()!=1 ){
      //   d.setDate(d.getDate() + (startweek * 7));
      // } else {
      //   d.setDate(d.getDate() + ((startweek-1) * 7));

      // }
      // var startdate = this.getMonday(d)
      let endyear = this.endw.substring(0, 4)
      let endweek = parseInt(this.endw.substring(6))
      // d = new Date(endyear, 0, 1);
      // d.setDate(d.getDate() + (endweek * 7));
      // var enddate = this.getMonday(d.getTime()-24*60*60*1000)
      var startdate = new Date(this.getWeekOfYear(startyear, startweek)[0])
      var enddate = new Date(this.getWeekOfYear(endyear, endweek)[6])
      let weeks = []
      while (startdate.setHours(0,0,0) <= enddate.setHours(0,0,0)) {
        // console.log("Loop", startdate, enddate)
        let week = {}
        week.startdate = new Date(startdate)
        let week_year = this.getWeekNumber(week.startdate)
        week.name = week_year[1]<10?"0"+week_year[1] + "-" + week_year[0]:week_year[1] + "-" + week_year[0]
        week.index = week_year[1]
        var lstartdate = this.getMonday(startdate)
        let thisweekfriday = new Date(startdate.getTime() + (4 * 24 * 60 * 60 * 1000))
        week.period = lstartdate.toString().slice(0, 10) + " <> " + thisweekfriday.toString().slice(0, 10)
        weeks.push(week)
        let numzzprers = this.visible_companies_gen.filter(vc => new Date(vc.sd).setHours(0,0,0,0) <= thisweekfriday.setHours(0,0,0,0) && new Date(vc.ed).setHours(0,0,0,0)>= lstartdate.setHours(0,0,0,0))
        week.numzzprers = [...new Set(numzzprers.map(item => item.user_id))].length
        startdate.setDate(startdate.getDate() + 7);
        // console.log(week)
      }
      return weeks
    },
    visible_companies() {
      if(this.visible_companies_gen.length==0){
        this.generate_visible_companies()
      }
      return this.owner==-1?this.visible_companies_gen:this.visible_companies_gen.filter(vc =>this.user_by_id(vc.user_id).owner_id == this.owner)
    },
    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'branches',
      'planning',
      'settings',
      'hours'
    ]),
    ...mapGetters([
      'project_by_id',
      'company_by_id',
      'contract_by_id',
      'zzpers',
      'user_by_id',
      'username_by_id'
      , 'createAny',
      'owners',

    ]),

  },
  methods: {
    downloadThisweek(){
      let startyear = this.startw.substring(0, 4)
      let startweek = parseInt(this.startw.substring(6))
      let endyear = this.endw.substring(0, 4)
      let endweek = Number(this.endw.substring(6))
      var startdate = new Date(this.getWeekOfYear(parseInt(startyear),startweek)[0])
      var enddate =  new Date(this.getWeekOfYear(parseInt(endyear),endweek)[6])   
      // var startdate = this.getMonday(new Date())
      // var enddate = this.getMonday(startdate.getTime()+6*24*60*60*1000)
      // console.log(startdate, enddate, startweek, endweek, d.getDay())
      let values = [];
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.user_by_id(this.contracts[i].user_id) != null) {
          let userplan = this.userplanning(this.contracts[i].user_id)
          // console.log(userplan)
          for (var p = 0; p < userplan.length; p++) {
            let row = {};
            let betweendates = false
            if (userplan[p].project_id == this.contracts[i].project_id) {
              let project = this.project_by_id(this.contracts[i].project_id)
              if (project) {
                row.project = userplan[p].project_name
                row.id = userplan[p].id
                row.inkoopfactuur = userplan[p].inkoopfactuur
                row.user_id = userplan[p].user_id
                row.project_id = userplan[p].project_id
                row.startdate = this.formatDate(userplan[p].startdate)
                row.enddate = this.formatDate(userplan[p].enddate)
                row.tarief = userplan[p].tarief
                row.verkooptarief = userplan[p].verkooptarief
                let projstartdate = new Date(userplan[p].startdate)
                let projenddate = new Date(userplan[p].enddate)

                if ((projstartdate <= enddate && projenddate >= startdate)) {
                  betweendates = true
                }
                let company = this.company_by_id(project.company_id)
                row.company_id = company.id
                row.company_name = company.company_name
                row.description = userplan[p].project_description
                row.branche_id = company.branche_id
                row.contract_id = this.contracts[i].id
                row.hours = this.has_hours(row.contract_id)
              }
                // console.log(">>", row)

              if (betweendates && row.branche_id == this.currentbranche || betweendates && this.currentbranche == -1) {
                if ((this.hashours == "yes" && row.hours > 0) || this.hashours == "doesnt" || (this.hashours == "no" && row.hours == 0)) {
                  if (this.filter == null) {
                    values.push(row)
                  } else {
                    if ((this.username_by_id(row.user_id).toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (this.project_by_id(row.project_id).title.toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (row.company_name.toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (row.description.toLowerCase().indexOf(this.filter.toLowerCase()) != -1)) {
                      values.push(row)
                    }
                  }
                }
              }
            }
          }
        }
      } 
      values.sort(this.companysort)
      console.log(values)
      var sep = ","
      let index = 0;
      var csv ="";

      csv += '"company_name"'+sep
      csv += '"zzper"'+sep
      csv += '"tarief"'+sep
      csv += '"marge"'+sep
      csv += '"inkoopfactuur"'+sep
      csv += '"verkooptarief"'+sep
      csv += '"brutomarge"'+sep
          csv+="\n";
          while (index<values.length){
            csv +=  '"'+values[index]["company_name"]+'"'+sep
            csv +=  '"'+this.username_by_id(parseInt(values[index]["user_id"]))+'"'+sep
            csv +=  '"'+parseFloat(values[index]["tarief"]).toFixed(2).replace(".", ",")+'"'+sep
            csv +=  '"'+parseFloat(parseFloat(values[index]["verkooptarief"])-parseFloat(values[index]["tarief"])).toFixed(2).replace(".", ",")+'"'+sep
            csv +=  '"'+values[index]["inkoopfactuur"]+'"'+sep
            csv +=  '"'+values[index]["verkooptarief"]+'"'+sep
            csv +=  '"'+parseFloat(((values[index]["verkooptarief"]-values[index]["tarief"])/values[index]["tarief"])*100).toFixed(2).replace(".", ",")+'"'+sep
            csv+="\n";
            index++
           }
           const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'Export.csv';
            anchor.click();       
     },
    generate_visible_companies(){
      let startyear = this.startw.substring(0, 4)
      let startweek = parseInt(this.startw.substring(6))
      // var d = new Date(startyear, 0, 1);
      // if(d.getDay()!=1 ){
      //   d.setDate(d.getDate() + (startweek * 7));
      // }else {
      //   d.setDate(d.getDate() + ((startweek-1) * 7));

      // }
      // var startdate = this.getMonday(d)
      let endyear = this.endw.substring(0, 4)
      let endweek = Number(this.endw.substring(6))
      console.log(startyear, startweek, endyear, endweek)
      // d = new Date(endyear, 0, 1);
      // d.setDate(d.getDate() + (endweek * 7));
      // var enddate = this.getMonday(d.getTime() + 7 * 24 * 60 * 60 * 1000)
      // enddate.setDate(enddate.getDate()-1)
      // d = new Date(endyear, 0, 1);
      //   d.setDate(d.getDate() + (endweek * 7));
      //   var enddate
      //   if(d.getDay()==1){
      //     enddate = new Date(d.getTime()-24*60*60*1000)
      //   } else{
      //     enddate = this.getMonday(d.getTime()-24*60*60*1000)
      //   }
      var startdate = new Date(this.getWeekOfYear(parseInt(startyear),startweek)[0])
      var enddate =  new Date(this.getWeekOfYear(parseInt(endyear),endweek)[6])      
      console.log(startdate, enddate, startweek, endweek)
      let thisweeksend = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
      let values = [];
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.user_by_id(this.contracts[i].user_id) != null) {
          let userplan = this.userplanning(this.contracts[i].user_id)
          for (var p = 0; p < userplan.length; p++) {
            let row = {};
            let betweendates = false
            if (userplan[p].project_id == this.contracts[i].project_id) {
              let project = this.project_by_id(this.contracts[i].project_id)
              if (project) {
                row.project = userplan[p].project_name
                row.id = userplan[p].id
                row.user_id = userplan[p].user_id
                row.project_id = userplan[p].project_id
                row.startdate = this.formatDate(userplan[p].startdate)
                row.enddate = this.formatDate(userplan[p].enddate)
                row.sd = userplan[p].startdate
                row.ed = userplan[p].enddate
                row.signed_zzpr = this.contracts[i].signed_zzpr
                row.signed_company = this.contracts[i].signed_company
                let projstartdate = new Date(userplan[p].startdate)
                let projenddate = new Date(userplan[p].enddate)
                if ((projstartdate <= enddate && projenddate >= startdate)) {
                  betweendates = true
                }

                let company = this.company_by_id(project.company_id)
                row.company_id = company.id
                row.company_name = company.company_name
                row.description = userplan[p].project_description
                row.branche_id = company.branche_id
                row.ending = projenddate < thisweeksend
                row.color = this.contracts[i].color
                row.contract_id = this.contracts[i].id
                row.hours = this.has_hours(row.contract_id)
                if(row.user_id==2291){
                console.log(row.contract_id , row.project_id, row.id)
                }
              }
                // console.log(">>", row)

              if (betweendates && row.branche_id == this.currentbranche || betweendates && this.currentbranche == -1) {
                if ((this.hashours == "yes" && row.hours > 0) || this.hashours == "doesnt" || (this.hashours == "no" && row.hours == 0)) {
                  if (this.filter == null) {
                    values.push(row)
                  } else {
                    if ((this.username_by_id(row.user_id).toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (this.project_by_id(row.project_id).title.toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (row.company_name.toLowerCase().indexOf(this.filter.toLowerCase()) != -1) ||
                      (row.description.toLowerCase().indexOf(this.filter.toLowerCase()) != -1)) {
                      values.push(row)
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (this.bycompany == 'yes') {
        values.sort(this.companysort)
      } else {
        values.sort(this.usersort)
      }
      this.visible_companies_gen = values
      this.totalRows = values.length
      // return values

    },
    blockcolor(id) {
      //  return { "box-shadow":"0px 0px 0px 5px "+this.contract_by_id(id).color+" inset " }
      if(this.contract_by_id(id)) {
        return { "background-color": this.contract_by_id(id).color }
      } else {
        return { "background-color": '#fff' }
      }
    },
    user(id) {
      return this.user_by_id(id)
    },
    companysort(a, b) {
      if (a.company_name < b.company_name) {
        return -1;
      }
      if (a.company_name > b.company_name) {
        return 1;
      }
      return 0;
    },
    usersort(a, b) {
      if (a.user_id < b.user_id) {
        return -1;
      }
      if (a.user_id > b.user_id) {
        return 1;
      }
      return 0;
    },
    ...mapActions([
      'save_contract',
    ]),
    color1(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[0].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[0].color
    },

    color2(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[1].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[1].color
    },
    color3(item) {
      this.contract_by_id(item.contract_id).color = this.settings.status_colors[2].color
      this.save_contract(item.contract_id)
      item.color = this.settings.status_colors[2].color
    },
    rowClass(item) {
      return { "style": "background-color:" + item.color }
    },

    startweekinit() {
      let now = new Date()
      let sweek = this.getWeekNumber(now)
      this.startw = parseInt(sweek[1]) < 10 ? sweek[0] + "-W0" + sweek[1] : sweek[0] + "-W" + sweek[1]
      let eweek = this.getWeekNumber(new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000))
      this.endw = parseInt(eweek[1]) < 10 ? eweek[0] + "-W0" + eweek[1] : eweek[0] + "-W" + eweek[1]
      // this.startw="2025-W01"
      // this.endw="2025-W02"
    },
    userplanning(user_id) {
      return this.planning.filter(p => p.user_id == user_id)
      // let _planning = []
      // for (var i = 0; i < this.planning.length; i++) {
      //   if (this.planning[i].user_id == user_id) {
      //     _planning.push(this.planning[i])
      //   }
      // }
      // return _planning
    },
    has_hours(contract_id) {
      return this.hours.filter(h => h.contract_id == contract_id).length
      // let daysFilled = 0;
      // for (var i = 0; i < this.hours.length; i++) {
      //   if (this.hours[i].contract_id == contract_id) {
      //     daysFilled++
      //   }
      // }
      // return daysFilled;

    },
    show_company(id) {
      this.$router.push({ path: '/company/' + id });
    },
  },
  watch: {
    startw: function () {
      let startyear = this.startw.substring(0, 4)
      let startweek = this.startw.substring(6)
      var sd = new Date(startyear, 0, 1);
      sd.setDate(sd.getDate() + (startweek * 7));
      if(this.endw!="") {
        let endyear = this.endw.substring(0, 4)
        let endweek = Number(this.endw.substring(6))
        let ed = new Date(endyear, 0, 1);
        ed.setDate(ed.getDate() + (endweek * 7));
        if (ed < sd) {
          //correct endweek to startweek +1
          let eweek = this.getWeekNumber(new Date(sd.getTime() + 7 * 24 * 60 * 60 * 1000))
          let newval = String(eweek[0])
          if(eweek[1]<10){
            newval+= "-W0"+String(eweek[1])
          } else {
            newval+= "-W"+String(eweek[1])
          }
          this.endw = newval
        }
      }
      // regenerate visible companies
      this.visible_companies_gen = [];
    },
    endw:function () {
      // regenerate visible companies
      this.visible_companies_gen = [];
    }

  },
  components: { UserWeekPlanning, UserPlanningBlock, CompanyPlanningsBlock },
  
}
</script>