<template>
  <div class="hello">
    <ckeditor v-model="dataText" :editor="editor" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FontSize from '@ckeditor/ckeditor5-build-classic';
// import FontFamily from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "HelloWorld",
  data() {
    return {
      pwtype: 'password',
      btnText: 'eye',
      dataText:"",
      new_emailsender: { id: 0, active: 1 },
      //eslint-disable-next-line
      reg: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items:[ 'heading', '|', 'undo', 'redo', 'fontSize', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ,'insertTable'],
          shouldNotGroupWhenFull: true
        },

        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        fontSize: {
            options: [
                'tiny',
                'default',
                'big'
            ]
        },
        plugin:{
          FontSize
        },      },
    }
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
