<template>
  <div v-if="activebonnen.length>0" id="hour-receipts">
    <h3 v-if="date==''">{{$t('titles.urenbon')}}</h3>
    <b-table :items="activebonnen" :fields="fields" thead-class="d-none">
      <template #cell(id)="row">
        <a :href="docto(row)" v-bind:target="'_blank'" class='action-item float-left'
          :title="$t('labels.view')+ ' '+ row.item.file">
          <font-awesome-icon icon="eye" />
        </a>
        <a v-if="row.item.id!=undefined &&  (current_user.role=='user' || current_user.role=='admin')"
          href="javascript:;" class="action-item" @click="deleteurenbon(row)"
          :title="$t('labels.deletefile')+ ' '+ row.item.file">
          <font-awesome-icon icon="trash" />
        </a>
      </template>
      <template #cell(file)="row">
        {{ nameonly(row) }}
      </template>

    </b-table>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import popup_modal from '../modals/PopupModal.vue'

export default {
  name: 'active_urenbonnen',
  data() {
    return {
      selectedID: null,

    }
  },
  props: {
    contract_id: Number,
    week_number: Number,
    year_number: Number,
    isclosed: Boolean,
    date: String
  },
  mounted() {
    console.log("mountUB",this.urenbonnen.filter(obj => {obj.contract_id === 1995}))
  },
  computed: {
    activebonnen() {
      return this.urenbonnen.filter(obj => {
        return obj.contract_id === this.contract_id
      }).filter(obj2 => {
        return obj2.week === this.week_number
      }).filter(yearobj => {
        return yearobj.year === this.year_number
      }).filter (intern => {
        if(this.date){
          return intern.name.search(this.date)!= -1
        } else {
          return (intern.name.substr(0,3)=='bon' || (this.createAny('users') && intern.name.substr(0, 6)=='intern')|| (this.createAny('users') && intern.name.substr(0, 7)=='company')) && this.notisweekday(intern.name)
        }
    })
    },
    fields() {
      return [
        { key: 'file', label: "" },
        { key: 'id', label: "" },
      ]
    },
    ...mapState([
      'contracts',
      'urenbonnen',
      'current_user',


    ]),
    ...mapGetters([
      'urenbon_by_id',
      'server_url',
      'confirm_modal',
      'createAny'

    ]),

  },

  methods: {
    ...mapActions([
      'save_hours',
      'add_hours',
      'agree_hours',
      'close_hours',
      'delete_urenbon'
    ]),
    notisweekday(name){
      let isWeekDay = true
      this.getWeekDates(this.week_number, this.year_number).forEach(day =>{
        if(name.indexOf(day)!=-1){
          isWeekDay = false
        }
      })
      return isWeekDay

    },

    docto(id) {
      return this.server_url + '/media_' + id.item.file;
    },
    nameonly(row) {
      return row.item.file.substr(row.item.file.indexOf("/")+1)
    },
    deleteurenbon(id) {
      this.selectedID = id.item.id
      let message = this.$i18n.t('labels.confirmdeletehead') + id.item.name + " " + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '90%'
      }, {
        'before-close': this.modalCloseDeleteUrenBon
      })

      this.$emit('close')
    },
    modalCloseDeleteUrenBon() {
      if (this.confirm_modal.id == 1) {
        let urenbon_id = this.selectedID
        this.delete_urenbon(urenbon_id)
        for (let i = 0; i < this.urenbonnen.length; i++) {
          if (this.urenbonnen[i].id == urenbon_id) {
            this.urenbonnen.splice(i, 1)
          }
        }
      }
    },
  },
  watch: {
  },
  components: {}
}
</script>